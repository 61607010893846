import * as React from "react";
import { AppBar } from "@mui/material";

import NavbarContent from "./NavbarContent";
import ScrollToColor from "./ScrollToColor";

const Navbar = () => {
  return (
    <ScrollToColor>
      <AppBar position="fixed" width="100%" elevation={0}>
        <NavbarContent />{" "}

      </AppBar>
    </ScrollToColor>
  );
};
export default Navbar;
