import * as React from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Toolbar,
  IconButton,
  Container,
  Button,
  useScrollTrigger,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "@material-ui/core";

import DrawerAppBar from "./Drawer";
import { MobilLogo, DesktopLogo } from "./Logo";
import { mainPages } from "../../pages/pages";

export default function NavbarContent() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  function showLogo(logo) {
    const LogoName = logo;
    return <LogoName />;
  }

  return (
    <Container maxWidth="lg">
      <Toolbar
        disableGutters
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "flex", md: "none" },
            my: 1,
          }}
        >
          <IconButton
            size="large"
            aria-label="menu icon"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleDrawerToggle}
            sx={{
              color: location.pathname === "/" && !trigger ? "#fff" : "#001f3f",
            }}
          >
            <MenuIcon
              sx={{
                fontSize: "2.5rem",
              }}
            />
          </IconButton>
          <DrawerAppBar
            pages={mainPages}
            handleDrawerToggle={handleDrawerToggle}
            mobileOpen={mobileOpen}
          />
        </Box>

        {showLogo(DesktopLogo)}

        <Box
          sx={{
            flexGrow: 2,
            display: { xs: "none", md: "flex" },
            justifyContent: "flex-end",
          }}
        >
          {mainPages.map((page) => (
            <Button
              key={page.name}
              sx={{
                my: 1,
                display: "block",
                fontWeight: "bold",
                color:
                  location.pathname === "/" && !trigger ? "#fff" : "primary",
              }}
              component={Link}
              href={page.path}
            >
              {page.name}
            </Button>
          ))}
          <Button
            variant="contained"
            size="large"
            sx={{
              m: 1,
              display: "block",
              fontWeight: "bold",
              px: 2,
              py: 1,
              backgroundColor: " rgb(240, 82, 44)",
              color: "white",
              "&:hover": {
                backgroundColor: "rgb(255, 102, 68)",
                transform: "scale(1.4)",
              },
            }}
            component={Link}
            href="tel:+3620-559-0387"
          >
            {" "}
            Hívás{" "}
          </Button>
        </Box>
        {showLogo(MobilLogo)}
      </Toolbar>
    </Container>
  );
}
