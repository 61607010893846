import { Typography, Grid } from "@mui/material";
import PacmanLoader from "react-spinners/PacmanLoader";

export default function Loading() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item>
        <Typography variant="h3" color="secondary">
          Betöltés...
          <PacmanLoader variant="h2" color="rgb(240, 82, 44)" size={40} />
        </Typography>
      </Grid>
    </Grid>
  );
}
