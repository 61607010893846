import React, { useEffect, Suspense, useId } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.scss";
import "fontsource-roboto";
import { Box, Toolbar } from "@mui/material";

import AOS from "aos";
import "aos/dist/aos.css";

import Navbar from "./components/Nav/Nav";
import FixedBottomNavigation from "./components/Footer";
import Loading from "./components/Loading/Loading";
import BackToTop from "./components/BackToTopButton";
import { mainPages, otherRoutes } from "./pages/pages";

import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";

let theme = createTheme();
theme = responsiveFontSizes(theme);
theme = createTheme(theme, {
  palette: {
    // text: {
    //   secondary: "rgb(0, 31, 63)",
    // },
    action: {
      active: "#a97142", 
    },
    primary: {
      main: 'rgb(0, 31, 63)', 
    },
    secondary: {
      main: "rgb(240, 82, 44)",
    }
    // background: {
    //   default: "#1E1F20" 
    // }
  },
});

function App() {
  const id = useId();

  useEffect(() => {
    AOS.init({
      offset: 120,
      duration: 2000,
      useClassNames: false,
      throttleDelay: 99,
      once: true,
      anchorPlacement: "top-bottom",
    });
    AOS.refresh();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        className="App"
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          justifyContent: "space-between",
        }}
      >
        <Navbar />
        <Suspense fallback={<Loading />}>
          <Toolbar id="back-to-top-anchor" />

          <Box sx={{ width: 1, mt: 5, pb: 10 }}>
            <Routes>
              {mainPages.map((page) => (
                <Route key={id} path={page.path} element={page.element} />
              ))}
              {otherRoutes.map((page) => (
                <Route key={id} path={page.path} element={page.element} />
              ))}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Box>
          <BackToTop />
        </Suspense>
        <FixedBottomNavigation />
      </Box>
    </ThemeProvider>
  );
}

export default App;
