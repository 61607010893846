import * as React from "react";

import { Grid, Container, Box, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import EmailIcon from "@mui/icons-material/Email";

export default function FixedBottomNavigation() {
  return (
    <footer>
      <CssBaseline />
      <Box
        px={{ xs: 3, sm: 5 }}
        py={{ xs: 5, sm: 5 }}
        sx={{ backgroundColor: "primary" }}
        color="white"
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5}>
              <Box borderBottom={1}>ProfAir Kft.</Box>

              <Box>
                <Typography variant="body1" sx={{ py: 1 }}>
                  Cégjegyzékszám 13-09-205696
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1">Adószám 27941175-2-13</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Box borderBottom={1}>Elérhetőség</Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap: 2,
                  alignItems: "center",
                  py: 0.5
                }}
              >
                <PhoneInTalkIcon sx={{ height: "0.8em", color: "white" }} />
                <Typography
                  color="inherit"
                  sx={{
                    textDecoration: "none",
                  }}
                  component="a"
                  variant="body1"
                  href="tel:+3620-559-0387"
                >
                  Telefonszám: +36 20 559 0387
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <EmailIcon sx={{ height: "0.8em", color: "white" }} />{" "}
                <Typography
                  color="inherit"
                  sx={{
                    textDecoration: "none",
                    my: 1,
                  }}
                  component="a"
                  variant="body1"
                  href="mailto:info@profair.hu"
                >
                  E-mail cím: info@profair.hu
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              sx={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }}
            >
              <Typography
                sx={{
                  textDecoration: "none",
                }}
                target="_blank"
                component="a"
                variant="body1"
                href="https://www.facebook.com/profair"
              >
                <FacebookIcon
                  sx={{ fontSize: 30,  color: "rgb(240, 82, 44)" }}
                />
              </Typography>
              <Typography
                sx={{
                  textDecoration: "none",
                }}
                target="_blank"
                component="a"
                variant="body1"
                href="https://www.instagram.com/profair"
              >
                <InstagramIcon
                  sx={{ fontSize: 30,  color: "rgb(240, 82, 44)", mx: 2 }}
                />
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box color="white" sx={{ py: 2 }} textAlign="center" borderTop={1}>
        <Container maxWidth="lg">
          <Box textAlign="center">
            <Typography
              color="white"
              sx={{
                textDecoration: "none",
              }}
              component="a"
              variant="body1"
              href="/adatvedelem"
            >
              Adatvédelmi tájékoztató
            </Typography>{" "}
          </Box>
        </Container>
      </Box>
    </footer>
  );
}
