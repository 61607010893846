import * as React from "react";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import { Link } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { MobilLogo } from "./Logo";

const drawerWidth = "70%";

function DrawerAppBar(props) {
  const { window, pages, handleDrawerToggle, mobileOpen } = props;
  const location = useLocation();

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <Box sx={{ my: 2 }}>
        <MobilLogo />
        <Typography sx={{ my: 1, textAlign: "center" }}>
          Klíma- és villanyszerelés
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ maxWidth: "90%", textAlign: "center" }}>
        <List>
          {pages.map((page) => (
            <ListItem
              key={`${page.name}drawer`}
              disablePadding
              sx={{
                maxWidth: "90%",
              }}
            >
              <ListItemButton selected={page.path === location.pathname}>
                <Typography
                  component={Link}
                  href={page.path}
                  sx={{
                    mt: 5,
                    fontWeight: "bold",
                    width: "100%",
                    textDecoration: "none",
                    textAlign: "center",
                  }}
                  activeclass="active"
                  color="primary"
                >
                  {page.name}
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box component="nav">
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

export default DrawerAppBar;
