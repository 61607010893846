import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@material-ui/core/Link";

export const MobilLogo = () => {
  return (
    <Box
      component={Link}
      href="/"
      sx={{
        display: { xs: "flex", md: "none" },
        flexDirection: "column",
        alignItems: "center",
        m: 1,
        textDecoration: "none",
        "&:hover": {
          textDecoration: "none",
        },
      }}
    >
      <Typography
        noWrap
        component="img"
        src={`${process.env.PUBLIC_URL}/assets/Profair.png`}
        sx={{
          width: { xs: "60%", sm: "35%" },
        }}
      ></Typography>
      {/* <Typography
        sx={{
          textDecoration: "none",
        }}
        color="primary"
      >
        {" "}
        Klíma- és villanyszerelés
      </Typography> */}
    </Box>
  );
};

export const DesktopLogo = () => {
  return (
    <Box
      component={Link}
      href="/"
      sx={{
        display: { xs: "none", md: "flex", flexDirection: "column" },
        my: 1,
        "&:hover": {
          textDecoration: "none",
        },
      }}
    >
      <Typography
        noWrap
        component="img"
        sx={{
          width: "35%",
          mb: 1,
        }}
        src={`${process.env.PUBLIC_URL}/assets/Profair.png`}
      ></Typography>
      {/* <Typography color="primary" variant="h6">
        {" "}
        Klíma- és villanyszerelés
      </Typography> */}
    </Box>
  );
};
