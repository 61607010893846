import React from "react";

const Home = React.lazy(() => import("./Home"));
const AirConditioners = React.lazy(() => import("./AirConditioners"));
const ElectricalInstallation = React.lazy(() => import("./ElectricalInstallation"));
const AirConditionerInstallation = React.lazy(() => import("./AirConditionerInstallation"));
const Contact = React.lazy(() => import("./Contact"));
const PrivacyPolicy = React.lazy(() => import("./PrivacyPolicy"));
const AirConditionerDetails = React.lazy(() =>
  import("../components/AirConditioners/AirConditionerDetails")
);

export const mainPages = [
  {
    name: "Kezdőlap",
    path: "/",
    element: <Home />,
  },
  {
    name: "Klímaszerelés",
    path: "/klimaszereles",
    element: <AirConditionerInstallation />,
  },
  {
    name: "Villanyszerelés",
    path: "/villanyszereles",
    element: <ElectricalInstallation />,
  },
  // {
  //   name: "Klímák",
  //   path: "/klimak",
  //   element: <AirConditioners />,
  // },
  {
    name: "Kapcsolat",
    path: "/kapcsolat",
    element: <Contact />,
  },
];

export const otherRoutes = [
  // {
  //   name: "Klíma részletek",
  //   path: "/klimak/:id",
  //   element: <AirConditionerDetails />,
  // },
  {
    name: "Adatvédelem",
    path: "/adatvedelem",
    element: <PrivacyPolicy />,
  },
];
